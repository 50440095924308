import type { Student, User } from "@generated/graphql";
import type { SelectedNavigation } from "@utils/use-selected-navigation";
import * as z from "zod";
import { getEnv, isDevelopment } from "./get-config";

export const isPlatformBrowser = () => {
  return typeof window !== "undefined";
};

const POSSIBLE_ENVS = z.enum(["local", "test", "staging", "production"]);
export type Env = z.infer<typeof POSSIBLE_ENVS>;

export const getEnvironment = (): Env => {
  const env = getEnv();
  const parsed = POSSIBLE_ENVS.safeParse(env);

  if (parsed.success) {
    return parsed.data;
  }
  if (isDevelopment()) {
    return "local";
  }

  throw new Error(`Invalid environment: ${env}.`, parsed.error);
};

type UserName = {
  firstName?: string;
  prefix?: string;
  lastName?: string;
};

/**
 * Function that takes an user object and returns the full name of this user
 * @param abbreviateLastName whether the last name of the user should be abbreviated to only use the first letter
 * @param user User object from which the full name will be extracted
 * @returns the name of the user with a abbreviated last name (firstName, prefix, first letter of the lastName)
 */
export const getFullUserName = (user: UserName | undefined, abbreviateLastName?: boolean) => {
  if (!user) return "";
  const abbreviatedUserName: UserName = { ...user, lastName: `${user.lastName?.charAt(0)}.` };
  const userName = abbreviateLastName ? abbreviatedUserName : user;
  return `${getNameValue(userName.firstName)}${getNameValue(userName.prefix)}${getNameValue(
    userName.lastName,
  )}`;
};

const getNameValue = (value: string | undefined) => {
  if (value) return `${value} `;
  return "";
};

export const getCommaSeparatedFullUserNamesList = (users: UserName[] | undefined): string => {
  return users?.map((user) => getFullUserName(user).trim()).join(", ") ?? "";
};
export const noop = () => {};

export const assertNever = (value: never): never => {
  throw new Error(`Unexpected value in assertNever: ${value}`);
};

export const kebabCaseItem = (item: string) => {
  const kebabCaseItem = item.replace(/\s+/g, "-").toLowerCase();
  const kebabCaseItemWithoutSpecialCharacters = kebabCaseItem.replace(/[^a-zA-Z0-9-]/g, "");
  return kebabCaseItemWithoutSpecialCharacters;
};

export const getIsTeacherOnlyValue = (
  selected: SelectedNavigation,
  enableFeatureForSpesificUser: boolean,
): boolean => {
  if (enableFeatureForSpesificUser) {
    return false;
  }

  const { selectedMethodGroup, selectedChapter, selectedParagraph, selectedLearningUnit } =
    selected;
  const isTeacherOnlyMethodGroup =
    selectedMethodGroup?.endUser?.length === 1 && selectedMethodGroup.endUser[0] === "teacher";
  const isTeacherOnlyChapter =
    selectedChapter?.endUser?.length === 1 && selectedChapter.endUser[0] === "teacher";
  const isTeacherOnlyParagraph =
    selectedParagraph?.endUser?.length === 1 && selectedParagraph.endUser[0] === "teacher";
  const isTeacherOnlyLearningUnit =
    selectedLearningUnit?.endUser?.length === 1 && selectedLearningUnit.endUser[0] === "teacher";

  if (selectedLearningUnit) {
    return (
      isTeacherOnlyMethodGroup ||
      isTeacherOnlyChapter ||
      isTeacherOnlyParagraph ||
      isTeacherOnlyLearningUnit
    );
  }
  if (selectedParagraph) {
    return isTeacherOnlyMethodGroup || isTeacherOnlyChapter || isTeacherOnlyParagraph;
  }
  if (selectedChapter) {
    return isTeacherOnlyMethodGroup || isTeacherOnlyChapter;
  }
  if (selectedMethodGroup) {
    return isTeacherOnlyMethodGroup;
  }

  return false;
};

export const unknownToError = (unknown: unknown): Error => {
  if (unknown instanceof Error) {
    return unknown;
  }

  return new Error(String(unknown));
};

export const getUserInfo = (users: User[] | undefined, userId: string | undefined) =>
  users?.find((user) => user.id === userId);

export const getLearningYear = (students: Student[] | undefined): string | undefined =>
  students
    ?.filter(({ yearGroup }) => !!yearGroup)
    .map((student) => student.yearGroup)
    .pop();
