import type { OpCo } from "@utils/use-brand";
import Image from "next/legacy/image";

export type OpCoComponents = {
  BundleTaskImage: React.ElementType;
};

export const BRAND_COMPONENTS: Record<OpCo, OpCoComponents> = {
  noordhoff: {
    BundleTaskImage: () => (
      <Image
        objectFit="cover"
        src="/images/planning-overview/bundle-noordhoff.webp"
        alt="logo"
        style={{
          borderRadius: "8px",
        }}
        width={50}
        height={50}
      />
    ),
  },
  plantyn: {
    BundleTaskImage: () => (
      <Image
        objectFit="cover"
        src="/images/planning-overview/bundle-plantyn.webp"
        alt="logo"
        style={{
          borderRadius: "8px",
        }}
        width={50}
        height={50}
      />
    ),
  },
  liber: {
    BundleTaskImage: () => (
      // For now we don't have a Liber image
      <Image
        objectFit="cover"
        src="/images/planning-overview/bundle-noordhoff.webp"
        alt="logo"
        style={{
          borderRadius: "8px",
        }}
        width={50}
        height={50}
      />
    ),
  },
};
