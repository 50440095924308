import type {
  EnvironmentOptions,
  Tracker,
  User,
} from "@infinitaslearning/module-data-analyticstracker";
import { isPlatformBrowser } from "@utils/common";
import { isEnabled } from "@utils/get-config";
import type { AnalyticsLocale } from "@utils/use-brand";

let analyticsTracker: Tracker | undefined;
let hasShowNotInitializedWarning = false;

const convertRolesToMostImportantOne = (roles: string[]): User["type"] => {
  // These if-clauses are set in this particular order, from roles with most privileges to least.
  if (roles.some((item) => "ambrasoft.ictcoordinator" === item.toLocaleLowerCase()))
    return "support";
  if (roles.some((item) => "ambrasoft.contentmanager" === item.toLocaleLowerCase()))
    return "manager";
  if (roles.some((item) => ["publisher", "ambrasoft.editor"].includes(item.toLocaleLowerCase())))
    return "author";
  if (roles.some((item) => "ambrasoft.administrator" === item.toLocaleLowerCase()))
    return "administrator";
  if (
    roles.some((item) =>
      [
        "teachers",
        "extra_teachers",
        "ambrasoft.stagiair",
        "ambrasoft.teacher",
        "ambrasoft.ibrter",
      ].includes(item.toLocaleLowerCase()),
    )
  )
    return "instructor";
  if (
    roles.some((item) =>
      ["ambrasoft.child", "ambrasoft.student", "ambrasoft.parent"].includes(
        item.toLocaleLowerCase(),
      ),
    )
  )
    return "learner";

  console.warn("The user roles are not valid for tracking", roles);
  return "learner";
};

export const initializeAnalyticsTracker = async (configuration: EnvironmentOptions) => {
  if (!isEnabled("NEXT_PUBLIC_ENABLE_ANALYTICS_TRACKER") || !isPlatformBrowser()) {
    return;
  }

  try {
    const { createTracker } = await import("@infinitaslearning/module-data-analyticstracker");
    analyticsTracker = createTracker(configuration);
  } catch (_error) {
    console.log("Could not load analytics tracker.");
  }
};

export const setAnalyticsTrackerUser = async (
  userId: string,
  organisationId: string,
  roles: string[],
  cultureInfo: AnalyticsLocale,
  classroomIds: string[],
): Promise<void> => {
  if (!userId || !Array.isArray(roles) || roles.length === 0) return;
  analyticsTracker?.identify({
    id: userId,
    type: convertRolesToMostImportantOne(roles),
    organisationId,
    cultureInfo,
    classroomIds,
  });
};

export const getAnalyticsTracker = (): Tracker | undefined => {
  if (!analyticsTracker && !hasShowNotInitializedWarning) {
    console.log("The analytics tracker has not been initialized.");
    hasShowNotInitializedWarning = true;
  }

  return analyticsTracker;
};
